<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/ecommerce/orders" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </span>
        </a>

        <a routerLink="/ecommerce/orders" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/small-logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
        </svg>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <!-- <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}"> -->
          <!-- <span class="bx bx-search-alt"></span> -->
        </div>
      </form>

      <div class="dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
      </div>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
          <span class="d-xl-inline-block ms-1">
            {{ user ? user.user.name : '' }} {{ user ? user.user.last_name : '' }}
          </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/contacts/profile"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            Perfil
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()" style="cursor: pointer;">
            <i class="bi bi-door-closed-fill font-size-16 align-middle me-1"></i>
            Cerrar Sesión
          </a>
        </div>
      </div>
    </div>
  </div>
</header>