import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxImageCompressService } from "ngx-image-compress";
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { ReactiveFormsModule } from '@angular/forms';

initializeApp(environment.firebase);

registerLocaleData(localeEs, "es");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbModule,
    ReactiveFormsModule
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: LOCALE_ID, useValue: "es" }, NgxImageCompressService],
})
export class AppModule {}
