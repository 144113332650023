<div id="layout-wrapper">

  <app-horizontaltopbar></app-horizontaltopbar>

  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="backoffice-footer">

    <div>
      {{year}} © Encargomio Backoffice.
    </div>

    <div>
      v{{ version.version ? version.version : 0 }}
    </div>

  </div>

</div>