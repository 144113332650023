import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { header, handleError } from "src/app/_helpers/tools/header.tool";
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  @Output() public countries: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private _storageService: StorageService
  ) {}

  getUsersAdmin() {
    return this.http
      .get<any>(`${environment.microservices.management}users/`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getUserById(id) {
    return this.http
      .get<any>(`${environment.microservices.management}users/detail`, {
        params: { id },
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getRandomUser(userEmail) {
    return this.http
      .get<any>(`${environment.microservices.management}users/random`, {
        params: { userEmail },
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getUsersAdminPaginate(params): Observable<any> {
    return this.http
      .get<any>(`${environment.microservices.management}users/paginate`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  createUser(user_type: number, user): Observable<any> {
    return this.http
      .post<any>(
        `${environment.microservices.management}users/insert_user`,
        user,
        {
          headers: header,
          params: { user_type: user_type == 1 ? "client" : "company" },
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  updateUser(user: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.microservices.management}users/`,
        { ...user },
        { headers: header, params: { id: user.id } }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getAddressByUser(params) {
    return this.http
      .get<any>(`${environment.microservices.management}users/addressess`, {
        headers: header,
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  deleteProductOnLocker(product: string): Observable<any> {
    return this.http
      .delete<any>(
        `${environment.microservices.management}locker/delete-product`,
        { headers: header, params: { product } }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getCitiesByDepartment(id: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.microservices.user}location/list-cities?id_departament=${id}`,
        {
          headers: header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  //Ojo a tener en cuenta
  getCities() {
    return this.http
      .get<any>(
        `${environment.microservices.user}location/list-cities?id_departament=2`,
        {
          headers: header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getDepartments(id?: any): Observable<any> {
    let token: string = this._storageService.getItem("currentUser").token;
    let _header: HttpHeaders = header.append(
      "Authorization",
      `Bearer ${token}`
    );
    return this.http
      .get<any>(
        `${
          environment.microservices.user
        }location/list-departaments?id_country=${id ? id : "1"}`,
        {
          headers: _header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getCountries(): Observable<any> {
    return this.http
      .get<any>(`${environment.microservices.user}location/list-countries`)
      .pipe(
        map((res: any) => {
          this.countries.emit(res);
          return res;
        }),
        catchError(handleError)
      );
  }

  updateAddress(payload) {
    return this.http
      .put<any>(
        `${environment.microservices.management}users/address`,
        payload,
        {
          headers: header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  // firebase push notifications methods;
  updateNotifyToken(payload) {
    let token: string = this._storageService.getItem("currentUser").token;
    let _header: HttpHeaders = header.append(
      "Authorization",
      `Bearer ${token}`
    );

    return this.http
      .put<any>(
        `${environment.microservices.management}notification/update-token`,
        payload,
        {
          headers: _header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  getPushNotifications() {
    let token: string = this._storageService.getItem("currentUser").token;
    let _header: HttpHeaders = header.append(
      "Authorization",
      `Bearer ${token}`
    );
    return this.http
      .get<any>(`${environment.microservices.management}notification`, {
        headers: _header,
      })
      .pipe(
        map((res: any) => {
          this.countries.emit(res);
          return res;
        }),
        catchError(handleError)
      );
  }

  deleteNotification(payload) {
    let token: string = this._storageService.getItem("currentUser").token;
    let _header: HttpHeaders = header.append(
      "Authorization",
      `Bearer ${token}`
    );

    return this.http
      .put<any>(
        `${environment.microservices.management}notification`,
        payload,
        {
          headers: _header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }

  markNotificationAsSeen(payload) {
    let token: string = this._storageService.getItem("currentUser").token;
    let _header: HttpHeaders = header.append(
      "Authorization",
      `Bearer ${token}`
    );

    return this.http
      .put<any>(
        `${environment.microservices.management}notification`,
        payload,
        {
          headers: _header,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(handleError)
      );
  }
}
