import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  HostListener,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { StorageService } from "src/app/_services/storage.service";
import { MENU } from "../sidebar/menu";
import { MenuItem } from "../sidebar/menu.model";
import { UserService } from "src/app/_services/users.service";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-horizontaltopbar",
  templateUrl: "./horizontaltopbar.component.html",
  styleUrls: ["./horizontaltopbar.component.scss"],
})
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {

  public newNotify: any = null;

  public menuItems: any = [];

  public user: any = {};

  public element: any;

  public innerWidth: number = 0;
  public isResponsive: boolean = false;

  public message: any = [];
  public messageNoReaded: any = [];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private _storage: StorageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initialize();
    this.user = this._storage.getItem("currentUser");
    this.element = document.documentElement;
    this.onResize();
    this.requestPermission();
    this.listen();
    this.getNotifications();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 900) {
      this.isResponsive = true;
    } else {
      this.isResponsive = false;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/account/login"]);
  }

  redirectToOrderTable(order) {
    this.router.navigate([`/ecommerce/orders`], {
      queryParams: {
        order_service: order,
      },
    });
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          if (this.user) {
            let token = this._storage.getItem("fcm");
            this._storage.setItem("fcm", currentToken);
            token = this._storage.getItem("fcm");
            this.goToUpdateToken(token, currentToken);
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      var audio = new Audio('/assets/notify.mp3');
      audio.play();
      this.newNotify = payload;
      this.message.unshift(payload);
      this.messageNoReaded.unshift(payload);
      setTimeout(() => {
        this.newNotify = null;
      }, 8000);
    });
  }

  viewMore() {
    this.redirectToOrderTable(this.newNotify.data.id);
    const isOrder = Boolean(this.newNotify.data.id);
    if (isOrder) {
      this.router.navigate([`/ecommerce/orders`], { queryParams: { order_service: this.newNotify.data.id } });
    } else {
      this.router.navigate([`/ecommerce/orders-shippings`], { queryParams: { shipping: this.newNotify.data.id } });
    }
  }

  goToUpdateToken(oldToken: string, newToken: string) {
    this._storage.removeItem("fcm");
    this.userService
      .updateNotifyToken({
        oldToken,
        newToken,
      })
      .subscribe(
        (res) => {
          if (res) {
            this._storage.setItem("fcm", newToken);
          }
        },
        (err) => {
          throw err;
        }
      );
  }

  getNotifications() {
    this.userService.getPushNotifications().subscribe((res) => {
      res.forEach((item) => {
        this.message.push(item);
        this.message.sort((a, b) => a.id - b.id);
        this.message.reverse();
      });
      this.messageNoReaded = this.message.filter((item) => item.status === 1);
    });
  }

  mapInformationTitle(item) {
    if (item.payload) {
      return item.payload.notification.title;
    } else {
      return item.notification.title;
    }
  }

  mapInformationBody(item) {
    if (item.payload) {
      return item.payload.notification.body;
    } else {
      return item.notification.body;
    }
  }

  removeNotification(index, notify_id) {
    this.message.splice(index, 1);
    this.userService
      .deleteNotification({ notification: notify_id, isDelete: true })
      .subscribe(
        (res) => res,
        (err) => {
          throw err;
        }
      );
  }

  markAsSeen(notify, index) {
    if (notify.status === 1) {
      const indexToRemoveInCounter = this.messageNoReaded.findIndex(
        (i: any) => i.id === notify.id
      );
      this.messageNoReaded.splice(indexToRemoveInCounter, 1);
    }

    // const indexSeened = this.message.findIndex((i: any) => i.id === notify.id);
    this.message[index].status = 2;
    this.userService
      .markNotificationAsSeen({ notification: notify.id })
      .subscribe(
        (res) => res,
        (err) => {
          throw err;
        }
      );

    if (notify.payload) {
      this.redirectToOrderTable(notify.payload.data.id);
    } else {
      this.redirectToOrderTable(notify.data.id);
    }
  }

  /**
   * On menu click
   */
  onMenuClick(event) {
    const windowWidth = window.innerWidth;
    const nextEl = event.target.nextElementSibling;
    if (windowWidth < 992) {
      if (nextEl) {
        const parentEl = event.target.parentNode;
        // este es el item del navbar
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.toggle("show");
      }
      return false;
    }
  }

  ngAfterViewInit() { }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById("topnav-menu-content");
    element.classList.toggle("show");
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
  }

  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = [];
    const permissions = this._storage.getItem("permissions");
    // RECORREMOS LOS PERMISOS
    let new_menu = MENU;
    //RECORREMOS LOS ITEMS PRINCIPALES
    new_menu.map((m, i) => {
      //BANDERA DE ACCESO AL MODULO
      let validate_menu = false;
      //RECORREMOS LOS SUBITEMS QUE TIENEN LOS ACCESOS A LOS MODULOS
      if (m.subItems) {
        m.subItems.map((sub) => {
          //VALIDAMOS QUE EL CODIGO RECORRIDO SEA IGUAL A MODULO AL QUE VAMOS A DAR ACCESO
          if (permissions[sub.code]) {
            //ANEXAMOS EL PERMISO AL MODULO
            //DAMOS ACCESO AL SELECTOR DEL MODULO
            sub.showItem = true;
            new_menu[i].showItem = true;
            validate_menu = true;
          } else {
            //DENEGAMOS EL ACCESO AL MODULO
            sub.showItem = false;
          }
        });

        //SI NO TENEMOS ACCESO AL MODULO LO DENEGAMOS
        if (!validate_menu) {
          new_menu[i].showItem = false;
        }
        validate_menu = false;
      }
    });
    //ASIGNAMOS EL MENU
    this.menuItems = new_menu;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
