<header id="page-topbar" style="z-index: 1 !important;">

  <div class="navbar-header">

    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/logo.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/logo.png" alt="" height="19">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="mdi mdi-menu"></i>
      </button>

      <!-- <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Buscar...">
          <span class="bx bx-search-alt"></span>
        </div>
      </form> -->

      <div class="dropdown dropdown-mega d-none d-lg-block me-2" ngbDropdown>
        <!-- 
        <button type="button" class="btn header-item" ngbDropdownToggle>
          Menú
          <i class="mdi mdi-chevron-down"></i>
        </button> -->

        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>

          <div class="row">

            <div class="col-lg">

              <div class="row">

                <div class="col-md-3" *ngFor="let item of menuItems">

                  <h5 class="font-size-14 mt-0">
                    <strong>{{ item.label }}</strong>
                  </h5>

                  <ul class="list-unstyled megamenu-list" *ngIf="!item.isTitle && !item.isLayout && item.showItem">

                    <li *ngFor="let subitem of item.subItems">
                      <a *ngIf="!hasItems(subitem) && subitem.showItem" [routerLink]="subitem.link">
                        {{ subitem.label }}
                      </a>
                    </li>

                  </ul>

                </div>

              </div>

            </div>

            <!-- 
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.UI.TITLE' }}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div> -->

          </div>

        </div>

      </div>

    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>

        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">
            <!-- {{ message && message.length > 0 ? message.length : 0 }} -->
            {{ messageNoReaded.length}}
          </span>
        </button>

        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notificaciones</h6>
              </div>
              <!-- <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' }}</a>
              </div> -->
            </div>
          </div>

          <!-- <ngx-simplebar style="position: relative;"> -->
          <div style="position: relative;">

            <a class="text-reset notification-item">

              <div class="notifications-container">
                <ng-container *ngIf="message && message.length > 0">
                  <div *ngFor="let item of message; let i = index" class="notification-card"
                    (click)="markAsSeen(item, i)" [ngClass]="{'notify-seen': item.status === 2}" ngbDropdownToggle>
                    <!-- <h4>{{item.payload.notification.title}}</h4> -->
                    <h4>{{mapInformationTitle(item)}}</h4>
                    <p>{{mapInformationBody(item)}}</p>
                    <span (click)="removeNotification(i, item.id)">
                      <i class="bx bxs-trash-alt size-icon"></i>
                    </span>
                  </div>
                </ng-container>
              </div>

              <div class="no-messages" *ngIf="messageNoReaded && messageNoReaded.length === 0;">
                No hay notificaciones nuevas
              </div>

            </a>

            <!-- <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' }}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' }}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' }}</p>
                  </div>
                </div>
              </div>
            </a> -->

          </div>
          <!-- </ngx-simplebar> -->

          <!-- <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              Cargar Más
            </a>
          </div> -->

        </div>

      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAACnElEQVQ4jW1TW0iTYRh+9m+6kwd0y02XuDBbw1UWRFZ0uOtORaKLSIOomw43kdCNIewqOlAXikJBzXOIRJ66CFJoLgmdkTs43HSmtK2l29rWv+3/93VR/+/UvXfv9z6H9/2+9xMgSxjbTXq1UnklTy5RAUA0RvvXA6HutjuXndnwfDx5OXxkcHzqvdO9GmZYlnDBsCxxuldDg2OTE89eDx3OSu4cGG20WO3LHCmZoInPu0RikRDJDIvV7unqG2ngeALO+dSxg29ra/RaQgg+mJ7jh3MWbPw3KGEO1IYTuHDtHm9msTqWzXPOupbrjQsUAGhURY9ra/RaAJgZ7YfP+hE5iQgkQoJcJBH4ZkbvgxtY/DIFADh5VL+vokzxEAAoY7tJX6OvPM6pe79OQ0RtH48iLKhoANaJN/zZIZ32tLGjW0eVKvc07ddqCrnCn8gGSBpIMCxolkVaBBSVF4AmLOjoJi9QVbG3UF2saBLJZGKVkNqyFIhykRbGcP6cAfn5Ekhluf/mtrjgXWF5nFBIQS4XqyiApDPbjYT90Bk0KFEV8GQAKCqWIxb/tfPxCBWLJwJsekujrKoc8jzxTiAk0lxoDlTwOcumEY3SfsoXDPYsrayHucLZSy2QyiS7BWQSnLl4l89dK2sh/+ZGD9V6q9kx7/TMcAWluhKhpHSXQDgphVJdyec2l/dT682mRQoAfMFgy+d5xzIACCgKpdV1cK3RSKUYpFIMXGs0ygz1EPy/7Ok5m+d7IHB/m0NX30jD9Jzds7XKcWIz9xObuZ8kE3F+lc2zC+7O3nf1u1oEgEcvhg0DY5PjDrc3xDAZn4lhiX3JuzkwNjn+9NVQdSZHkE3I2NGtUysUzXKZuAQAYrGEfz3409R2+6prJ/YvfPlSUVz+UzEAAAAASUVORK5CYII="
            alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{ user.user ? user.user.name : ''}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Perfil
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Cerrar Sesión
          </a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->

    </div>

  </div>

</header>

<div class="topnav" style="z-index: 1 !important;">

  <div class="container-fluid">

    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">

      <div class="collapse navbar-collapse active" id="topnav-menu-content">


        <ng-container *ngFor="let item of menuItems">
          <ul class="navbar-nav">

            <!-- con esta condiconal ocultamos el label del header menu si el usuario no posee el permiso correspondiente -->
            <ng-container *ngIf="item.showItem">
              <li class="nav-item dropdown">

                <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                  id="topnav-components" [routerLink]="item.link" role="button">
                  <i class="bx {{item.icon}} me-2"></i>{{ item.label }} <div class="arrow-down" *ngIf="hasItems(item)">
                  </div>
                </a>



                <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                  href="javascript: void(0);" id="topnav-components" role="button">
                  <i class="bx {{item.icon}} me-1"></i>
                  {{item.label }}
                  <div class="arrow-down"></div>
                </a>


                <div class="dropdown-menu" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">

                  <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">

                    <!-- este ng container valida que subitem.showItem sea True para mostrar el label -->
                    <ng-container *ngIf="subitem.showItem">
                      <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)"
                        href="javascript: void(0);" [routerLink]="subitem.link"
                        routerLinkActive="active">{{subitem.label }}</a>
                    </ng-container>

                    <div class="dropdown" *ngIf="hasItems(subitem)">

                      <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                        (click)="onMenuClick($event)">{{ subitem.label }}
                        <div class="arrow-down"></div>
                      </a>

                      <div class="dropdown-menu">

                        <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                          <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                            href="javascript: void(0);" [routerLink]="subSubitem.link" routerLinkActive="active">{{
                            subSubitem.label }}</a>
                          <div class="dropdown" *ngIf="hasItems(subSubitem)">

                            <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                              (click)="onMenuClick($event)">{{ subSubitem.label }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                                <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                  [routerLink]="subSubSubitem.link" routerLinkActive="active">{{ subSubSubitem.label
                                  }}</a>
                              </ng-template>
                            </div>
                          </div>
                        </ng-template>

                      </div>

                    </div>

                  </ng-template>

                </div>

              </li>
            </ng-container>

          </ul>

        </ng-container>


      </div>

    </nav>

  </div>

</div>


<div class="container-push-notify animate__animated"
  [ngClass]="{ 'notify-push-open animate__slideInRight' : newNotify?.notification, 'animate__slideOutRight visibility-hide': !newNotify }">

  <div class="notify-title">
    ¡Nueva notificación!
  </div>

  <div class="notify-push">

    <div class="container-image">
      <img src="https://i.imgur.com/bOAp9ux.jpg" alt="" draggable="false">
    </div>

    <div class="container-text">
      <div>
        {{ newNotify ? newNotify.notification.title : ''}}
      </div>
      <div>
        {{ newNotify ? newNotify.notification.body : ''}}
      </div>
    </div>

  </div>

  <div class="text-align-end">
    <a (click)="viewMore()">Ver más</a>
  </div>

</div>