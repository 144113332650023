import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class NotifyService {

  constructor() { }

  show(title: string, message: string, type: any) {
    Swal.fire(`${title}`, `${message}`, type);
  }

  confirm(title: string, text: string): Promise<boolean> {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then(result => {
      return result.isConfirmed;
    });
  }

}
